// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/api/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"a85991b9b926572d0a4b6f05951828f53ab2454a"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  debug: false,
  integrations: [Sentry.extraErrorDataIntegration({ captureErrorCause: true })],
});
